/* eslint-disable import/no-cycle */
import {
  PortableText as OriginalPortableText,
  PortableTextComponents,
  PortableTextProps,
} from '@portabletext/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { QuoteType, TitleDescriptionLinkType, VideoType, imageWithAltRichText } from '../types';
import { Image } from './Image';
import LinkStandalone from './LinkStandalone';
import Quote from './Quote';
import Table from './Table';
import CallToActionCard from './cards/CallToActionCard';
import FactBox, { FactType } from './cards/FactBox';
import { Faq } from './Faq';

const components: PortableTextComponents = {
  block: {
    h1: ({ children }) => (
      <h1 className="mx-auto mb-4 max-w-2xl text-center text-2xl font-semibold md:mt-10 md:text-5xl md:leading-[4rem]">
        {children}
      </h1>
    ),
    h2: ({ children }) => (
      <h2 className="md:text-display-2 mb-4 mt-10 text-3xl font-semibold md:mt-14">{children}</h2>
    ),
    h3: ({ children }) => (
      <h3 className="mb-4 mt-7 text-[16px] font-medium md:mt-6 md:text-[20px]">{children}</h3>
    ),
    h4: ({ children }) => <h4 className="">{children}</h4>,
    h5: ({ children }) => <h5 className="">{children}</h5>,
    normal: ({ children }) => <p className="mb-4 text-base sm:text-lg">{children}</p>,
  },

  marks: {
    strong: ({ children }) => <strong className="font-semibold">{children}</strong>,
    em: ({ children }) => <em>{children}</em>,
    internalLink: ({ children, value }) => {
      return (
        <Link
          href={value.internalLink || ''}
          className="decoration-moller-color-primary underline decoration-2 underline-offset-4 duration-200 hover:decoration-transparent"
        >
          {children}
        </Link>
      );
    },
    externalLink: ({ children, value }) => {
      return (
        <Link
          href={value.externalLink || ''}
          className="decoration-moller-color-primary underline decoration-2 underline-offset-4 duration-200 hover:decoration-transparent"
        >
          {children}
        </Link>
      );
    },
    emailLink: ({ value, children }) => {
      return (
        <a
          className="decoration-moller-color-primary underline decoration-2 underline-offset-4 duration-200 hover:decoration-transparent"
          href={`mailto:${value?.email}`}
        >
          {children}
        </a>
      );
    },
    phoneLink: ({ value, children }) => {
      return (
        <a
          className="decoration-moller-color-primary underline decoration-2 underline-offset-4 duration-200 hover:decoration-transparent"
          href={`tel:${value?.phone}`}
        >
          {children}
        </a>
      );
    },
  },

  list: {
    bullet: ({ children }) => (
      <ul className="portable-list marker:text-moller-color-primary my-6 ml-5 flex list-disc flex-col gap-2 marker:text-base marker:font-medium md:gap-3 md:text-lg md:marker:text-xl">
        {children}
      </ul>
    ),
    number: ({ children }) => {
      return (
        <ol className="portable-list marker:text-moller-color-primary my-6 ml-5 flex list-decimal flex-col gap-2 marker:text-base marker:font-medium md:gap-3 md:text-lg md:marker:text-xl">
          {children}
        </ol>
      );
    },
  },

  types: {
    fact: ({ value }: { value: FactType }) => {
      return <FactBox value={value} />;
    },
    anyLinkMenu: ({ value }) => {
      return <LinkStandalone title={value.linkText} url={value.href} />;
    },
    comboImage: ({ value }) => {
      const { imageWide, imageSquare, swap } = value;

      return (
        <article className="richtext-image my-8 lg:-mx-40 lg:my-16">
          <div className="flex flex-col gap-5 lg:grid lg:grid-cols-5">
            <div className={`col-span-3 ${swap ? 'order-last' : 'order-first'} min-h-full`}>
              <Image
                src={imageWide}
                quality={100}
                width={(700 / 9) * 16}
                height={700}
                className="mx-auto h-full w-full rounded-sm object-cover lg:rounded-md"
              />
              {(imageWide.caption || imageWide.credits) && (
                <p className="flex flex-col gap-2 pt-2 text-base md:pt-4 md:text-base lg:hidden">
                  {imageWide.caption}
                  {imageWide.credits && (
                    <span className="text-primary/80 text-[0.625rem] leading-[1rem] lg:mt-2 lg:text-sm">
                      Foto: {imageWide.credits}
                    </span>
                  )}
                </p>
              )}
            </div>
            <div className="col-span-2">
              <Image
                src={imageSquare}
                quality={100}
                width={(700 / 9) * 16}
                height={700}
                className="mx-auto h-full w-full rounded-sm object-cover lg:rounded-md"
              />
              {(imageSquare.caption || imageSquare.credits) && (
                <p className="flex flex-col gap-2 pt-2 text-base md:pt-4 md:text-base lg:hidden">
                  {imageSquare.caption}
                  {imageSquare.credits && (
                    <span className="text-primary/80 text-[0.625rem] leading-[1rem] lg:mt-2 lg:text-sm">
                      Foto: {imageSquare.credits}
                    </span>
                  )}
                </p>
              )}
            </div>
          </div>
          <div className="max-lg:hidden lg:grid lg:grid-cols-5 lg:gap-5">
            <div className={`col-span-3 ${swap ? 'order-last' : 'order-first'} min-h-full`}>
              {(imageWide.caption || imageWide.credits) && (
                <p className="flex flex-col gap-2 pt-2 text-base md:pt-4 md:text-base">
                  {imageWide.caption}
                  {imageWide.credits && (
                    <span className="text-primary/80 text-[0.625rem] leading-[1rem] lg:mt-2 lg:text-sm">
                      Foto: {imageWide.credits}
                    </span>
                  )}
                </p>
              )}
            </div>
            <div className="col-span-2">
              {(imageWide.caption || imageWide.credits) && (
                <p className="flex flex-col gap-2 pt-2 text-base md:pt-4 md:text-base">
                  {imageSquare.caption}
                  {imageSquare.credits && (
                    <span className="text-primary/80 text-[0.625rem] leading-[1rem] lg:mt-2 lg:text-sm">
                      Foto: {imageSquare.credits}
                    </span>
                  )}
                </p>
              )}
            </div>
          </div>
        </article>
      );
    },
    imageWithAltRichText: ({ value }: { value: imageWithAltRichText }) => {
      if (!value?.asset) return null;

      const { isWide, caption, credits } = value;

      return (
        <article className={`my-8 lg:my-16 ${isWide && 'lg:-mx-40'} richtext-image`}>
          <Image
            src={value}
            quality={100}
            width={(1000 / 9) * 16}
            height={1000}
            className="mx-auto w-full rounded-sm lg:rounded-md"
          />
          {(caption || credits) && (
            <p className="flex flex-col gap-2 pt-2 text-sm md:pt-4 md:text-base">
              {caption}
              {credits && (
                <span className="text-primary/80 text-[0.625rem] leading-[1rem] lg:mt-2 lg:text-sm">
                  Foto: {credits}
                </span>
              )}
            </p>
          )}
        </article>
      );
    },
    videoRichText: ({
      value,
    }: {
      value: { showTitle: boolean; showDescription: boolean; video: VideoType };
    }) => {
      const {
        showTitle,
        showDescription,
        video: { url, title, description },
      } = value;

      const Video = dynamic(() => import('react-player/vimeo'), {
        loading: () => (
          <div className="my-10 flex aspect-video w-full flex-col items-center justify-center gap-5 bg-black/90 text-slate-300">
            <span className="h-5 w-5 animate-spin rounded bg-slate-300" />
            <span className="font-medium tracking-wide">Laster inn video...</span>
          </div>
        ),
        ssr: false,
      });

      return (
        <div className="my-10 flex flex-col gap-3 overflow-hidden rounded-md">
          {title && showTitle && <h3 className="text-display-3">{title}</h3>}
          {description && showDescription && <p>{description}</p>}
          <Video
            url={url}
            width="100%"
            height="100%"
            controls
            config={{
              playerOptions: {
                responsive: true,
              },
            }}
          />
        </div>
      );
    },
    quote: ({ value }: { value: QuoteType }) => {
      return <Quote quoteText={value.quoteText} quotePerson={value.quotePerson} />;
    },
    titleDescriptionLink: ({ value }: { value: TitleDescriptionLinkType }) => {
      return <CallToActionCard value={value} />;
    },
    tableWithTitle: ({ value }) => {
      return <Table tableWithTitle={value} />;
    },
    faq: ({ value }) => {
      return <Faq title={value.titleFrontend} questions={value.questions} />;
    },
  },
};

function PortableText({ value }: PortableTextProps) {
  return <OriginalPortableText value={value} components={components} />;
}

export default PortableText;

import Link from 'next/link';
import { useState } from 'react';
import { FormOverrideType } from '../../types';
import Feedback from './Feedback';
import FormContainer from './containers/FormContainer';
import ResizablePanel from './containers/ResizablePanel';
import IFormSchema from './types/IFormSchemaJSON';

interface FormProps {
  formOverride: FormOverrideType;
  formData: IFormSchema | string;
  dealerId?: string;
  onlyFields?: boolean;
  findDealer?: boolean;
}

function Form({ formOverride, formData, dealerId, onlyFields, findDealer = true }: FormProps) {
  const [completed, setCompleted] = useState(false);
  const animationDuration = 0.5;

  if (typeof formData === 'string') {
    return null;
  }

  if (!formOverride.form) return null;

  return (
    <ResizablePanel duration={animationDuration}>
      {completed ? (
        <Feedback duration={animationDuration - 0.2} />
      ) : (
        <div className="pt-8">
          {!onlyFields && (
            <h2 className="text-display-2 mb-4">
              {formOverride?.title ? formOverride.title : formOverride.form.title}
            </h2>
          )}
          <p className="mb-4 text-lg">
            {formOverride?.description ? formOverride.description : formOverride.form.description}
          </p>
          <FormContainer
            formTitle={formOverride.form?.previewTitle || ''}
            formName={formOverride.form.formName}
            formSchemaJSON={formData}
            dealerId={dealerId}
            setCompleted={setCompleted}
          />
          {findDealer && (
            <>
              <hr className="mb-8 mt-12" />

              <div className="flex gap-2">
                <span>Ønsker du heller å kontakte oss direkte?</span>
                <Link
                  href="/forhandler"
                  className="decoration-moller-color-primary underline decoration-2 underline-offset-4 duration-200 hover:decoration-transparent"
                >
                  Finn forhandler
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </ResizablePanel>
  );
}

export default Form;
